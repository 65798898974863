import React, { useState, useEffect, useRef, memo, lazy, Suspense, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { PERMISSOES } from '../../common/Constants';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import EnvioImagens from '../../componentes/envio-imagens'
import { temPermissaoRBAC } from '../../secutity/acl';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { CAMPOS_PACIENTE } from './camposPacientes';
import { initialValues } from './initialValues';
import { makeStyles } from '@material-ui/core/styles';

const Paciente = lazy(() => import('@stt-componentes/paciente'));
const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const InformacoesClinicas = lazy(() => import('../../componentes/solicitacao/informacoes-clinicas'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    buttonWrapper: {
        marginTop: theme.spacing(2)
    },
}));

const campos = CAMPOS_PACIENTE;

const Solicitacao = () => {
    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const user = useSelector(state => state.index.user);

    const schema = validationSchema(strings, campos.camposCadastro);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
        abrirSecao('paciente', true);
    }

    const handleAbrirModalEnvioImagens = () => {
        setMostrarAlerta(false);
        setMostrarModalEnvioImagens(temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME));
    }

    //Envio de imagens
    const [enviarImagens, setEnviarImagens] = useState(false);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    const [solicitacao, setSolicitacao] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'paciente': true,
        'solicitante': false,
        'informacoesClinicas': false,
    });

    // Impressão do termo de autorização
    const [confirmarTermo, setConfirmarTermo] = useState(false);
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [instituicaoSolicitante, setInstituicaoSolicitante] = useState(null);

    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoInfoClicnicas = useRef(null);

    // Controle do envio de imagens
    const [confirmarEnvioExame, setConfirmarEnvioExame] = useState(false);

    const imprimirTermo = (idPaciente, instituicaoSolicitante) => {
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        const idBuffer = Buffer.from(idPaciente.toString());
        let url = `${global.gConfig.url_base_eeg}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${instituicaoSolicitante.uf}`;
        axios.get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroGerarTermo);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setPaciente(null);
                setInstituicaoSolicitante(null);
                setGerarTermo(false);
            });
    }

    useEffect(() => {
        if (gerarTermo) {
            imprimirTermo(paciente, instituicaoSolicitante);
        } else {
            setPaciente(null);
            setInstituicaoSolicitante(null);
        }
    }, [gerarTermo]);

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno['paciente']) {
                abrirSecao('paciente', true);
                node = secaoPaciente.current;
            } else if (retorno['solicitante']) {
                abrirSecao('solicitante', true);
                node = secaoSolicitante.current;
            } else if (retorno['informacoesClinicas']) {
                abrirSecao('informacoesClinicas', true);
                node = secaoInfoClicnicas.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
        });
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    let dados = { ...data };
                    dados.digitador = {
                        id: user.idFuncionario
                    }
                    if (dados.paciente) {
                        if (dados.paciente.cpf) {
                            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
                        }
                        if (dados.paciente.cep) {
                            dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
                        }
                    }
                    let tipoAlertaSolicitacao = '';
                    let tituloAlertaSolicitacao = '';
                    let mensagemAlertaSolicitacao = '';

                    axios.post(`${global.gConfig.url_base_eeg}/solicitacao`, dados, { headers: getHeaders() })
                        .then((response) => {
                            const dadosSolicitacao = response.data.data;
                            const dadosPaciente = dadosSolicitacao.paciente;

                            dadosSolicitacao.resultadoExames = dados.informacoesClinicas.resultadoExames;
                            dadosSolicitacao.instituicao = dados.solicitante.instituicao;

                            setSolicitacao(dadosSolicitacao);
                            setPaciente(dadosPaciente);
                            setInstituicaoSolicitante(dados.solicitante.instituicao);
                            tipoAlertaSolicitacao = 'success';
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: () => {
                                    handleCloseAlerta();
                                    setConfirmarTermo(true);
                                }
                            }]);
                            tituloAlertaSolicitacao = strings.sucesso;
                            mensagemAlertaSolicitacao = response.data.message;
                            resetForm();
                        })
                        .catch(err => {
                            const { response } = err;
                            let msg = strings.erroDesconhecido;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const dadosResposta = response.data;
                                    let arrMensagem = [];
                                    dadosResposta.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = dadosResposta.message;
                                    mensagemAlertaSolicitacao = msg;
                                } else {
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = strings.erro;
                                    mensagemAlertaSolicitacao = msg;
                                }
                            } else {
                                tipoAlertaSolicitacao = 'error';
                                tituloAlertaSolicitacao = strings.erro;
                                mensagemAlertaSolicitacao = msg || strings.erroDesconhecido;
                            }
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: handleCloseAlerta
                            }]);
                        })
                        .finally(() => {
                            setSubmitting(false);
                            setTipoAlerta(tipoAlertaSolicitacao);
                            setTituloAlerta(tituloAlertaSolicitacao);
                            setMensagemAlerta(mensagemAlertaSolicitacao);
                            setMostrarAlerta(true);
                        });
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        submitCount,
                        validateForm,
                        setFieldValue
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    {/* Dados do paciente */}
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secoesAbertas['paciente']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('paciente', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    campos={campos}
                                                    imc
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }}
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Solicitante */}
                                    <SttExpansionPanel
                                        title={strings.solicitante}
                                        opened={secoesAbertas['solicitante']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('solicitante', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Informações clínicas */}
                                    <SttExpansionPanel
                                        title={strings.infoClinicas}
                                        opened={secoesAbertas['informacoesClinicas']}
                                        callback={estadoInterno => abrirSecao('informacoesClinicas', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoInfoClicnicas}></div>
                                                <InformacoesClinicas />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    <div className={classes.buttonWrapper}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            nomarginleft="true"
                                            onClick={() => verificarSecoesComErro(validateForm)}
                                        >
                                            {strings.enviar}
                                        </SttButton>
                                    </div>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                                <Alerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={handleCloseAlerta}
                                />
                                <Alerta
                                    open={confirmarTermo}
                                    title={strings.termoEsclarecimento}
                                    message={strings.mensagemConfirmarTermo_solicitacao}
                                    type={'confirmation'}
                                    options={[
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                setGerarTermo(true);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        },

                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        }
                                    ]}
                                    onClose={() => {
                                        setConfirmarTermo(false);
                                        if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                            setConfirmarEnvioExame(true);
                                        }
                                    }}
                                />

                                {
                                    temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                                    <Alerta
                                        open={confirmarEnvioExame}
                                        title={strings.tituloConfirmarEnvioExame}
                                        message={strings.mensagemConfirmarEnvioExame}
                                        type={'confirmation'}
                                        options={[
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    setEnviarImagens(true);
                                                    setConfirmarEnvioExame(false);
                                                    handleAbrirModalEnvioImagens();
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setSolicitacao(false);
                                                    setConfirmarEnvioExame(false);
                                                }
                                            }
                                        ]}
                                        onClose={() => {
                                            setSolicitacao(false);
                                            setConfirmarEnvioExame(false);
                                        }}
                                    />
                                }
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) && solicitacao && enviarImagens &&
                <EnvioImagens
                    modalOpen={mostrarModalEnvioImagens}
                    resetFormulario={() => {
                        setSolicitacao(false);
                        setMostrarModalEnvioImagens(false);
                        setEnviarImagens(false);
                    }}
                    solicitacao={solicitacao}
                />
            }
        </>
    );
};

export default Solicitacao;