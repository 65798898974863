import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { resetFiltros } from '../../reducers/exame';
import { SttTabs } from '@stt-componentes/core';
import { PERFIL, PERMISSOES } from '../../common/Constants';
import { TIPO_ABA_EXAME, VINCULO_PERFIL } from '../../componentes/exame/constantes';
import { temPermissaoRede } from '../../secutity/acl';

import Aba from '../../componentes/exame/aba';

const Exame = () => {
    const user = useSelector(state => state.index.user);
    const dispatch = useDispatch();
    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');

    const navigate = useNavigate();
    const location = useLocation();

    const resetAba = () => {
        navigate('/exames');
        dispatch(resetFiltros());
    }

    useEffect(() => {
        let dadosAbas = [];
        if (user.perfisRBAC) {

            const perfisInteresse = user.perfisRBAC.filter(p => [
                PERFIL.ADMINISTRADOR, 
                PERFIL.ADMINISTRADOR_ESTADUAL,
                PERFIL.MEDICO_LAUDADOR, 
                PERFIL.MEDICO_SOLICITANTE, 
                PERFIL.REGULADOR_EXAME, 
                PERFIL.TECNICO, 
                PERFIL.VISUALIZADOR,
                PERFIL.VISUALIZADOR_REDE
            ].includes(p.identificador));
            perfisInteresse.forEach(perfil => {
                // INSTITUIÇÃO
                if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
                    perfil.instituicoes.forEach(instituicao => {
                        dadosAbas.push({
                            id: instituicao.id,
                            tipo: TIPO_ABA_EXAME.INSTITUICAO,
                            titulo: instituicao.nome,
                            conteudo: Aba,
                            handleClick: resetAba                  
                        });
                    })
                }
    
                // REDE
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                        if (!redeJaAdd) {
                            dadosAbas.push({
                                id: rede.id,
                                tipo: TIPO_ABA_EXAME.REDE,
                                titulo: rede.descricao,
                                conteudo: Aba,
                                laudador: temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA) ? 1 : 0,
                                handleClick: resetAba
                            });
                        }
                    });
                }
        
                // GLOBAL
                if (perfil.vinculo === VINCULO_PERFIL.GLOBAL || 
                    perfil.identificador === PERFIL.ADMINISTRADOR_ESTADUAL || 
                    perfil.identificador === PERFIL.MEDICO_SOLICITANTE) {
                    dadosAbas.push({
                        id: perfil.identificador,
                        tipo: TIPO_ABA_EXAME.PERFIL,
                        titulo: `Perfil ${perfil.descricao}`,
                        conteudo: Aba,
                        handleClick: resetAba
                    });
                }
    
            });
            setAbas(dadosAbas);
        }
    }, [user]);

    useEffect(() => {
        if (location.state?.aba) {
            setAbaAtiva(''+location.state.aba);
        }
    }, [location]);

    return (
        <SttTabs abas={abas} abaAtiva={abaAtiva} />
    );

}

export default Exame;