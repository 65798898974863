import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { ...other } = props;
    const classes = useStyles();

    const [gerandoPdf, setGerandoPdf] = useState(false);

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const EEG_API_BASE_URL = global.gConfig.url_base_eeg;

    const imprimir = (idExame) => {
        setGerandoPdf(true);
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: {
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                imagens: true
            }
        };

        axios.get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                setGerandoPdf(false);
                window.open(response.data);
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
            });
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = {
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios
            .get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                setGerandoPdf(false);
                window.open(response.data);
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
            });
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        const idBuffer = Buffer.from(exame.id_paciente.toString());
        let url = `${EEG_API_BASE_URL}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${exame.uf}`;
        axios
            .get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                setGerandoPdf(false);
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
            });

    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

export default Aba;